import { gql } from '@apollo/client';

export const PROJECTS_QUERY = gql`
    query Projects($where: ProjectWhereInput, $orderBy: ProjectOrderByInput, $cursor: ProjectWhereUniqueInput, $skip: Int, $take: Int) {
        findManyProject(where: $where, orderBy: $orderBy, cursor: $cursor, skip: $skip, take: $take) {
            name
            __typename
        }
    }
`;

export const TEAMS_QUERY = gql`
    query findManyModerationTeam($where: ModerationTeamWhereInput) {
        findManyModerationTeam(where: $where, orderBy: { createdAt: desc }) {
            name
            creator {
                name
            }
            createdAt
            moderationTeamId
            moderators {
                name
            }
        }
    }
`;

export const MODERATIONTEAMS_QUERY = gql`
    query ModerationTeams(
        $where: ModerationTeamWhereInput
        $orderBy: ModerationTeamOrderByInput
        $cursor: ModerationTeamWhereUniqueInput
        $skip: Int
        $take: Int
        $withModerators: Boolean = false
        $withCreator: Boolean = false
    ) {
        findManyModerationTeam(where: $where, orderBy: $orderBy, cursor: $cursor, skip: $skip, take: $take) {
            moderationTeamId
            name
            updatedAt
            createdAt
            ... @include(if: $withCreator) {
                creator {
                    name
                }
            }
            ... @include(if: $withModerators) {
                moderators {
                    name
                }
            }
            __typename
        }
    }
`;

export const MODERATIONUSERS_QUERY = gql`
    query ModerationUsers(
        $where: ModerationUserWhereInput
        $orderBy: ModerationUserOrderByInput
        $cursor: ModerationUserWhereUniqueInput
        $skip: Int
        $take: Int
        $withTeam: Boolean = false
        $withAgency: Boolean = false
        $withPrivileges: Boolean = false
        $withCreator: Boolean = false
    ) {
        findManyModerationUser(where: $where, orderBy: $orderBy, cursor: $cursor, skip: $skip, take: $take) {
            moderationUserId
            lastLogin
            name
            role
            ... @include(if: $withCreator) {
                creator {
                    name
                }
            }
            creatorId
            updatedAt
            createdAt
            ... @include(if: $withPrivileges) {
                privileges {
                    moderationUserPrivilegeId
                }
            }
            minCharAwr
            minCharMessage
            ... @include(if: $withTeam) {
                teams {
                    name
                    moderationTeamId
                }
            }
            ... @include(if: $withAgency) {
                agency {
                    name
                }
            }
            agencyId
            fixedConversation
            factor
            languageCode
            chatStatus
            projects {
                projectName
            }
            __typename
        }
    }
`;

export const POOLS_QUERY = gql`
    query Pools($where: PoolWhereInput, $orderBy: PoolOrderByInput, $cursor: PoolWhereUniqueInput, $skip: Int, $take: Int) {
        findManyPool(where: $where, orderBy: $orderBy, cursor: $cursor, skip: $skip, take: $take) {
            poolId
            name
            updatedAt
            createdAt
            creator {
                name
            }
            priority
            activeFrom
            activeTo
            type
            isDynamic
            filledAt
            periodUnit
            fillPeriod
            poolTeams {
                moderationTeam {
                    name
                    moderationTeamId
                }
            }
            poolModerationUsers {
                moderationUser {
                    name
                    moderationUserId
                }
            }
            moderationTeam {
                moderationTeamId
                name
            }
            moderationUser {
                moderationUserId
                name
            }
            targetProfiles {
                poolTargetProfileId
            }
            sourceProfiles {
                poolSourceProfileId
            }
            projectName
            count
            __typename
        }
    }
`;

export const TEAM_MUTATION_CREATE = gql`
    mutation createOneModerationTeam($data: ModerationTeamCreateInput!) {
        createOneModerationTeam(data: $data) {
            name
            moderationTeamId
        }
    }
`;
export const TEAM_MUTATION_UPDATE = gql`
    mutation updateOneModerationTeam($data: ModerationTeamUpdateInput!, $where: ModerationTeamWhereUniqueInput!) {
        updateOneModerationTeam(data: $data, where: $where) {
            moderationTeamId
        }
    }
`;

export const POOL_MUTATION_CREATE = gql`
    mutation createOnePool($data: PoolCreateInput!) {
        createOnePool(data: $data) {
            poolId
            name
        }
    }
`;

export const TEAM_MUTATION_DELETE = gql`
    mutation deleteOneModerationTeam($where: ModerationTeamWhereUniqueInput!) {
        deleteOneModerationTeam(where: $where) {
            name
        }
    }
`;

export const POOL_MUTATION_DELETE = gql`
    mutation deleteOnePool($where: PoolWhereUniqueInput!) {
        deleteOnePool(where: $where) {
            name
            __typename
        }
    }
`;

export const POOLSOURCEPROFILES_MUTATION_DELETE = gql`
    mutation deleteManyPoolSourceProfile($poolId: String!) {
        deleteManyPoolSourceProfile(where: { poolId: { equals: $poolId } }) {
            count
        }
    }
`;

export const POOLSOURCEPROFILE_MUTATION_DELETE = gql`
    mutation deleteOnePoolSourceProfile($poolId: String!, $moderatedProfileId: String!, $projectName: String!) {
        deleteOnePoolSourceProfile(where: { pI_mPI_pN: { poolId: $poolId, moderatedProfileId: $moderatedProfileId, projectName: $projectName } }) {
            poolSourceProfileId
            moderatedProfile {
                moderatedProfileId
                profile {
                    profileName
                }
            }
        }
    }
`;

export const POOLTARGETPROFILES_MUTATION_DELETE = gql`
    mutation deleteManyPoolTargetProfile($poolId: String!) {
        deleteManyPoolTargetProfile(where: { poolId: { equals: $poolId } }) {
            count
        }
    }
`;

export const POOLTARGETPROFILE_MUTATION_DELETE = gql`
    mutation deleteOnePoolTargetProfile($poolTargetProfileId: String!) {
        deleteOnePoolTargetProfile(where: { poolTargetProfileId: $poolTargetProfileId }) {
            poolTargetProfileId
            targetProfile {
                profileName
            }
        }
    }
`;

export const MODERATORS_STATISTIC_GROUPBY_QUERY = gql`
    query findManyModeratorStatisticGroupBy($where: ModeratorStatisticWhereInputWithoutReference, $take: Int = 10, $skip: Int) {
        findManyModeratorStatisticGroupBy(
            where: $where
            by: [moderatorId]
            orderBy: [{ moderatorId: asc }]
            take: $take
            skip: $skip
            _avg: { processingTime: true, chars: true }
            _sum: {
                try: true
                success: true
                coins: true
                bonus: true
                ins: true
                outs: true
                chars: true
                processingTime: true
                messages: true
                pokes: true
                pictures: true
                friendRequests: true
                gifts: true
            }
        ) {
            moderator {
                name
                lastLogin
                role
                minCharAwr
                minCharMessage
                fixedConversation
                factor
                createdAt
                teams {
                    moderationTeamId
                    name
                }
                agency {
                    agencyId
                    name
                }
                projects {
                    projectName
                    moderationUserProjectId
                }
                creator {
                    name
                }
                privileges {
                    moderationPrivilege
                }
            }
            moderatorId
            _sum {
                try
                success
                coins
                bonus
                ins
                outs
                chars
                processingTime
                messages
                pokes
                pictures
                friendRequests
                gifts
            }
            _avg {
                processingTime
                chars
            }
        }
    }
`;

export const MODERATORS_QUERY = gql`
    query findManyModerationUser($where: ModerationUserWhereInput) {
        findManyModerationUser(where: $where) {
            moderationUserId
            name
            lastLogin
            role
            minCharAwr
            minCharMessage
            fixedConversation
            factor
            createdAt
            teams {
                moderationTeamId
                name
            }
            agency {
                agencyId
                name
            }
            role
            languageCode
            projects {
                projectName
                moderationUserProjectId
            }
            creator {
                name
            }
        }
    }
`;

export const MODERATORS_STATISTIC_QUERY = gql`
    query findManyModeratorStatisticAggregate($id: String!) {
        findManyModeratorStatisticAggregate(
            aggregate: {
                where: { moderatorId: { equals: $id } }
                _sum: {
                    try: true
                    success: true
                    coins: true
                    bonus: true
                    ins: true
                    outs: true
                    chars: true
                    processingTime: true
                    messages: true
                    pokes: true
                    pictures: true
                    friendRequests: true
                    gifts: true
                }
            }
        ) {
            _sum {
                try
                success
                coins
                bonus
                ins
                outs
                chars
                processingTime
                messages
                pokes
                pictures
                friendRequests
                gifts
            }
        }
    }
`;

export const POOL_MUTATION_UPDATE = gql`
    mutation updateOnePool($data: PoolUpdateInput!, $poolId: String!) {
        updateOnePool(where: { poolId: $poolId }, data: $data) {
            poolId
            name
            __typename
        }
    }
`;

export const PROFILES_QUERY_COUNT = gql`
    query findManyModeratedProfileCount($where: ModeratedProfileWhereInput) {
        findManyModeratedProfileCount(where: $where)
    }
`;

export const PROFILES_QUERY = gql`
    query findManyModeratedProfile($where: ModeratedProfileWhereInput, $take: Int = 50, $skip: Int = 0) {
        findManyModeratedProfile(where: $where, take: $take, skip: $skip, orderBy: { moderatedProfileId: desc }) {
            moderatedProfileId
            profileId
            profile {
                profileName
                gender
                searches
                country
                flirtText
                hidden
                active
                images {
                    path
                    main
                    private
                }
                birthDate
                shownLocation
                createdAt
                count {
                    pictures
                }
            }
            teams {
                teamId
                team {
                    name
                }
            }
            creator {
                name
            }
            isFSK18
            support
            dynamic
            projectName
            fixed
            noNAWR
        }
    }
`;

export const POOLTARGETPROFILES_QUERY = gql`
    query findManyPoolTargetProfile(
        $poolId: String
        $where: UserWhereInput
        $take: Int
        $skip: Int = 0
        $withImages: Boolean = false
        $withProfileInterests: Boolean = false
        $withProfileFlags: Boolean = false
        $withStatistics: Boolean = false
    ) {
        findManyPoolTargetProfile(
            where: { poolId: { equals: $poolId }, targetProfile: { user: $where } }
            take: $take
            skip: $skip
            orderBy: { targetProfileId: desc }
        ) {
            poolId
            poolTargetProfileId
            targetProfile {
                user {
                    userId
                    email
                    mobileNumber
                    lastAction
                    lastMessageReceived
                    lastMessageSend
                    role
                    username
                    updatedAt
                    createdAt
                    createdIP
                    sendTransactionalEmail
                    sendMarketingEmail
                    sendSMS
                    mobileNumberValidated
                    mobileNumberValidatedAt
                    emailValidated
                    emailValidatedAt
                    conditionAcceptedAt
                    client
                    clientVersion
                    c4fId
                    hasPayed
                    verified
                    coins
                    bonus
                    latitude
                    longitude
                    projectName
                    profileId
                    profile {
                        profileId
                        profileName
                        gender
                        searches
                        country
                        timezone
                        postcode
                        furtherInformation
                        themeName
                        adminText
                        languageCode
                        hidden
                        hideLocation
                        birthDate
                        createdAt
                        shownLocation
                        flirtText
                        ... @include(if: $withProfileFlags) {
                            profileFlags {
                                flag {
                                    name
                                }
                                createdAt
                            }
                        }
                        ... @include(if: $withImages) {
                            images {
                                imageId
                                path
                                main
                                private
                                fsk18
                                projectName
                                verified
                            }
                        }
                        ... @include(if: $withProfileInterests) {
                            profileInterests {
                                interest {
                                    name
                                }
                            }
                        }
                    }
                    socials {
                        providerName
                        socialId
                        providerId
                    }
                    ... @include(if: $withStatistics) {
                        userStatistic {
                            ins
                            sentMessages
                            spentBonus
                            spentCoins
                        }
                    }
                }
            }
        }
    }
`;

export const POOLTARGETPROFILES_COUNT_QUERY = gql`
    query findManyPoolTargetProfileCount($poolId: String, $where: UserWhereInput) {
        findManyPoolTargetProfileCount(where: { poolId: { equals: $poolId }, targetProfile: { user: $where } })
    }
`;

export const POOLSOURCEPROFILES_QUERY = gql`
    query findManyPoolSourceProfile($poolId: String, $where: ModeratedProfileWhereInput, $take: Int, $skip: Int = 0) {
        findManyPoolSourceProfile(
            where: { poolId: { equals: $poolId }, moderatedProfile: $where }
            take: $take
            skip: $skip
            orderBy: { moderatedProfileId: desc }
        ) {
            poolId
            poolSourceProfileId
            moderatedProfileId
            moderatedProfile {
                profileId
                profile {
                    profileName
                    gender
                    searches
                    country
                    flirtText
                    images {
                        path
                        main
                        private
                    }
                    birthDate
                    shownLocation
                    createdAt
                }
                teams {
                    teamId
                    team {
                        name
                    }
                }
                creator {
                    name
                }
                isFSK18
                support
                dynamic
                projectName
                fixed
            }
        }
    }
`;

export const AGENCY_QUERY = gql`
    query findManyAgency($where: AgencyWhereInput, $take: Int, $skip: Int = 0) {
        findManyAgency(where: $where, take: $take, skip: $skip, orderBy: { agencyId: desc }) {
            agencyId
            name
            factor
            createdAt
            creator {
                name
            }
            projects {
                projectName
            }
            _count(select: { adminUsers: true, moderationUsers: true, agencyStatistics: true, projects: false, agencyHourStatistics: false }) {
                adminUsers
                moderationUsers
                agencyStatistics
            }
        }
    }
`;

export const AGENCY_MUTATION_CREATE = gql`
    mutation createOneAgency($data: AgencyCreateInput!) {
        createOneAgency(data: $data) {
            agencyId
        }
    }
`;
export const AGENCY_MUTATION_UPDATE = gql`
    mutation updateOneAgency($data: AgencyUpdateInput!, $where: AgencyWhereUniqueInput!) {
        updateOneAgency(data: $data, where: $where) {
            agencyId
        }
    }
`;

export const AGENCY_MUTATION_DELETE = gql`
    mutation deleteOneAgency($where: AgencyWhereUniqueInput!) {
        deleteOneAgency(where: $where) {
            name
        }
    }
`;

export const AGENCYPROJECT_MUTATION_DELETE = gql`
    mutation deleteOneAgencyProject($where: AgencyProjectWhereUniqueInput!) {
        deleteOneAgencyProject(where: $where) {
            agencyProjectId
        }
    }
`;

export const MODERATEDPROFILE_MUTATION_UPDATE = gql`
    mutation updateManyModeratedProfile($data: ModeratedProfileUpdateManyInput!, $where: ModeratedProfileWhereInput!) {
        updateManyModeratedProfile(data: $data, where: $where) {
            count
        }
    }
`;
