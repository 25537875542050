import { gql } from '@apollo/client';

export const EMAIL_VALIDATE_MUTATION = gql`
    mutation emailValidate($where: UserWhereUniqueInput!) {
        validateEmail(where: $where) {
            userId
            adminChanged
            updatedAt
            adminUserId
        }
    }
`;

export const MOBILE_VALIDATE_MUTATION = gql`
    mutation validateMobileNumber($where: UserWhereUniqueInput!) {
        validateMobileNumber(where: $where) {
            userId
            adminChanged
            updatedAt
            adminUserId
        }
    }
`;

export const STATISTIC_AGGREGATE_QUERY = gql`
    query findManyUserStatisticAggregate($where: UserStatisticWhereInput, $take: Int = 1, $skip: Int = 0) {
        findManyUserStatisticAggregate(
            where: $where
            take: $take
            skip: $skip
            _sum: { ins: true, sentPokes: true, sentMessages: true, sentGifts: true, sentPictures: true, spentCoins: true, spentBonus: true }
        ) {
            _sum {
                ins
                sentPictures
                sentGifts
                sentMessages
                sentPokes
                spentBonus
                spentCoins
            }
        }
    }
`;

export const COINS_MUTATION = gql`
    mutation changeCoins($where: UserWhereUniqueInput!, $data: UserCoinsUpdateInput!) {
        changeCoins(where: $where, data: $data) {
            userId
            coins
            bonus
            email
            username
        }
    }
`;

export const TEXT_UNVERIFIED_COUNT_QUERY = gql`
    query CountUnverifiedUser {
        findManyUserCount(where: { profile: { flirtText: { not: "" }, flirtTextVerified: { equals: false } } })
    }
`;

export const TEXT_UNVERIFIED_QUERY = gql`
    query FindUnverifiedUsers {
        findManyProfile(
            where: { flirtTextVerified: { equals: false }, flirtText: { not: "" }, user: { userId: { not: "" } } }
            take: 1
            orderBy: { createdAt: desc }
        ) {
            user {
                username
                email
                createdAt
                userId
                role
            }
            profileId
            projectName
            profileName
            birthDate
            country
            postcode
            gender
            searches
            flirtText
        }
    }
`;
