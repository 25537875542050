import { gql } from '@apollo/client';

export const USERCOMPLAINS_QUERY = gql`
    query findManyUserComplain($where: UserComplainWhereInput, $take: Int = 10, $skip: Int = 0) {
        findManyUserComplain(where: $where, orderBy: {}, take: $take, skip: $skip) {
            complainTypeName
            createdAt
            name
            projectName
            reporter {
                username
                userId
                profileId
            }
            reporterId
            target {
                profileId
                profileName
            }
            targetId
            text
            userComplainId
        }
    }
`;

export const USERCOMPLAIN_MUTATION = gql`
    mutation deleteOneUserComplain($userComplainId: String!, $projectName: String!) {
        deleteOneUserComplain(where: { userComplainId_projectName: { projectName: $projectName, userComplainId: $userComplainId } }) {
            ... on UserComplain {
                id
            }
            ... on ResultError {
                errors {
                    errorMessage
                    errorCode
                }
            }
        }
    }
`;

export const MODCOMPLAINS_QUERY = gql`
    query findManyModeratorComplain($where: ModeratorComplainWhereInput, $take: Int = 10, $skip: Int = 0) {
        findManyModeratorComplain(where: $where, orderBy: { createdAt: desc }, take: $take, skip: $skip) {
            moderationComplainType
            createdAt
            languageCode
            status
            projectName
            reporter {
                moderationUserId
                name
            }
            reporterId
            target {
                profileId
                username
            }
            targetId
            text
            moderatorComplainId
        }
    }
`;

export const MODCOMPLAIN_MUTATION = gql`
    mutation updateOneModeratorComplain($where: ModeratorComplainWhereUniqueInput!, $data: ModeratorComplainUpdateInput!) {
        updateOneModeratorComplain(where: $where, data: $data) {
            moderationComplainType
            createdAt
            languageCode
            status
            projectName
            reporter {
                moderationUserId
                name
            }
            reporterId
            target {
                profileId
                username
            }
            targetId
            text
            moderatorComplainId
        }
    }
`;
