import React, { useEffect } from 'react';
import { HashRouter, Route, Navigate, Outlet, useLocation, Routes } from 'react-router-dom';
import './scss/style.scss';
import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { ToastProvider } from './contexts/ToastContext';
import { LogProvider } from './contexts/LogContext';
import { LogTypeInputEnum } from './generated/graphql';
import { useSelector } from 'react-redux';
import { IRootState } from './types';
import { useLogContext } from './hooks';

const graphqlLink = createHttpLink({
    uri: process.env.REACT_APP_ENDPOINT,
    credentials: 'same-origin',
});
const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `${token}` : '',
        },
    };
});
const addDateLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
        if (response.data) response.data.date = new Date();
        else response.data = { date: new Date() };
        return response;
    });
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
            if (message === 'Context creation failed: jwt expired' || message === 'Not Authorised!') {
                localStorage.removeItem('token');
            }
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        });

    if (networkError) console.log(`[Network error]: ${networkError}`);
});

const clientApollo = new ApolloClient({
    //link: authLink.concat(graphqlLink),
    link: from([authLink, errorLink, addDateLink, graphqlLink]),
    cache: new InMemoryCache(),
});

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const TheLayoutSimple = React.lazy(() => import('./containers/TheLayoutSimple'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const PrivateRoute = () => {
    const locationNow = useLocation();
    const [from, setFrom] = React.useState(locationNow.pathname);
    const { log } = useLogContext();
    const activeProject = useSelector((state: IRootState) => state.activeProject);

    const token = localStorage.getItem('token');
    const isAuthed = !!token;

    useEffect(() => {
        if (isAuthed && activeProject && from !== locationNow.pathname) {
            console.log('log pageview', locationNow.pathname);
            if (from !== locationNow.pathname) {
                log({
                    data: {
                        projectName: activeProject,
                        logData: {
                            target: locationNow.pathname,
                        },
                        type: LogTypeInputEnum.Pageview,
                    },
                });
            }
            setFrom(locationNow.pathname);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationNow, isAuthed]);

    return isAuthed ? <Outlet /> : <Navigate to="/login" />;
};

class App extends React.Component {
    render(): JSX.Element {
        return (
            <ApolloProvider client={clientApollo}>
                <HashRouter>
                    <React.Suspense fallback={loading}>
                        <LogProvider>
                            <ToastProvider>
                                <Routes>
                                    <Route path="/login" element={<Login />} />
                                    <Route path="/404" element={<Page404 />} />
                                    <Route path="/500" element={<Page500 />} />
                                    <Route element={<PrivateRoute />}>
                                        <Route path="*" element={<TheLayout />} />
                                        <Route path="user/*" element={<TheLayoutSimple />} />
                                    </Route>
                                </Routes>
                            </ToastProvider>
                        </LogProvider>
                    </React.Suspense>
                </HashRouter>
            </ApolloProvider>
        );
    }
}

export default App;
